import Keycloak from 'keycloak-js';
import React from 'react';
import config from '../config';

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
export const keycloak = new Keycloak({
    url: config.keycloakAuthURL,
    realm: config.keycloakRealm,
    clientId: config.keycloakClientId,
});

// This represents the keycloak object of an authenticated user.
// So far, these are the 3 fields that we use and "should" be present whenever the user is authenticated
export const isAuthenticated = (keycloak) => {
    return keycloak.authenticated === true;
};

// Get user's full name if gven
export const getUserFullName = (keycloak) => {
    if (keycloak && keycloak.tokenParsed) {
        const { given_name, family_name } = keycloak.tokenParsed;
        //
        if (given_name && family_name) {
            return `${given_name} ${family_name}`;
        }
    }
    return "";
};

export const AuthenticatedComponent = (props) => {
    // Assuming that AuthenticatedProps is not used in the JS version
    // and AuthenticatedComponent is a regular functional component
    // that can accept any props.
    // If you want to mimic AuthenticatedProps, you can manually check if `props.keycloak` is authenticated.
    const isAuth = isAuthenticated(props.keycloak);

    // Your component logic goes here...

    if (isAuth) {
        return <>{props.children}</>;
    } else {
        // You might handle the case where the user is not authenticated
        // You can return null, a login prompt, or any other component.
        return null;
    }
};

