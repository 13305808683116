import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const VERSION =  `beta ${(process.env.REACT_APP_VERSION || 'v0.0.0')}` ;

export const VersionNumber = ({ onClicksMetCallback }) => {
  const [clickCount, setClickCount] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [password, setPassword] = useState('');
  const [inputPassword, setInputPassword] = useState('');
  const [authenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (clickCount >= 10) {
      const timer = setTimeout(() => {
        setClickCount(0);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [clickCount]);

  const handleTextClick = () => {
    if (clickCount < 10) {
      setClickCount(prevCount => prevCount + 1);
    } else {
      setShowPopup(true);
    }
  };

  const handlePopupClose = () => {
    setShowPopup(false);
    setInputPassword('');
  };

  const handlePasswordSubmit = () => {
    if (inputPassword === '4949') {
      setAuthenticated(true);
      navigate('/*', { replace: true });
      
      // Triggering the event callback
      if (onClicksMetCallback !== undefined) {
        onClicksMetCallback(); 
      }
    }
    handlePopupClose();
  };

  return (
    <div style={{ position: 'relative', height: '100vh' }}>
      <p
        className="version-text noselect"
        onClick={handleTextClick}
        style={{
          position: 'fixed',
          bottom: '10px',
          right: '10px',
          cursor: 'pointer',
          color: '#ffffffbf', // Semi-transparent white
          fontSize: '12px', // Shrink the font size
          userSelect: 'none', // Prevent text selection
        }}
      >
        {VERSION}
      </p>
      {showPopup && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            background: '#fff',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
            zIndex: '999',
          }}
        >
          <h2 style={{ color: 'rgba(0, 0, 0, 0.7)' }}>Admin</h2>
          <input
            value={inputPassword}
            onChange={e => setInputPassword(e.target.value)}
          />
          <button onClick={handlePasswordSubmit}>Submit</button>
          <button onClick={handlePopupClose}>Cancel</button>
        </div>
      )}
    </div>
  );
};

export default VersionNumber;
