export const generateGuestUID = () => {
    let d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now(); // use high-precision timer if available
    }
    return 'guest-' + 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
};

export const checkGuestExpiration = () => {
    const expirationTime = localStorage.getItem('expirationTime');
    if (expirationTime) {
        const currentTime = new Date().getTime();
        if (currentTime >= parseInt(expirationTime)) {
            localStorage.removeItem('expirationTime');
            localStorage.removeItem('guestToken');
            localStorage.removeItem('guestID');
            localStorage.removeItem('isGuest');
            console.log('Data expired. Cleared saved data.');
            return true; // Data expired
        } else {
            console.log('Data still valid until:', new Date(parseInt(expirationTime)).toLocaleString());
            return false; // Data still valid
        }
    } else {
        console.log('No expiration data found.');
        return true; // No expiration data found
    }
};

export const  isGuest = () => {
    checkGuestExpiration();
    return localStorage.getItem('isGuest') === 'true';
};

export const SignInAsGuest = async () => {
    try {
        if (checkGuestExpiration()) {
            localStorage.setItem('guestID', generateGuestUID());
            var bodyText = `grant_type=client_credentials&device_id=${localStorage.getItem('guestID')}`;
            const response = await fetch('https://auth.streamsix.com/auth/realms/Users/protocol/openid-connect/token', {
                method: 'POST',
                headers: {
                    Authorization: 'Basic dHAtZ3Vlc3Q6YTZkZWM0ZGEtNGNlZC00ZDYwLThlZjYtNTY2MzI0Mjc3NzVl',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: bodyText
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log(`guest info: ${JSON.stringify(data)}`);
            const seconds  = data.expires_in; // server returns: 18000, which is about 5 hours
            localStorage.setItem('expirationTime', new Date().getTime() + (seconds * 1000)); 
            localStorage.setItem('guestToken', data.access_token);
            localStorage.setItem('isGuest', 'true');
        }
        
        console.log(`[SignInAsGuest] Guest token: ${localStorage.getItem('guestToken')}`);
        console.log(`[SignInAsGuest] Guest Data Expires at: ${new Date(parseInt(localStorage.getItem('expirationTime'))).toLocaleString()}`);
        console.log(`[SignInAsGuest] GuestID is: ${localStorage.getItem('guestID')}`);
        window.location.reload();
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};